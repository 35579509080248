import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Home from "./views";

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/" render={() => <Home />} />
      <Route path="*" exact render={() => <h4>Não Encontrado</h4>} />
    </Switch>
  </BrowserRouter>
);

export default Routes;
