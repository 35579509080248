//CSS
import "./index.css";

import Logo from "../images/logo.png";

function Home() {
  return (
    <div className="main">
      <div className="box-logo">
        {/* <span className="logo">Joy</span> */}
        <img src={Logo} alt="" />
      </div>
      <div className="box-servicos">
        <h2>Conheça nossos tratamentos, eles são Personalizados e Unissex</h2>
        <div className="box-servicos__content">
          <h3>
            <strong>Tratamentos Corporais e Faciais</strong>
          </h3>
          <ul className="box-servicos__lista-servico">
            <li>- Drenagem Ortomolecular.</li>
            <li>- Drenagem Ortomolecular com tratamento para celulite.</li>
            <li>- Drenagem pré e pós operatório.</li>
            <li>
              - Limpeza de Pele Ortomolecular e com peeling enzimatico Peeling
              Químico para Rosto e Olheiras.
            </li>
          </ul>
        </div>

        <div className="box-servicos__content">
          <h3>
            <strong>Tratamentos Terapêuticos</strong>
          </h3>
          <ul className="box-servicos__lista-servico">
            <li>- Acupuntura Holística.</li>
            <li>- Harmonização Energética Holística com Cristais.</li>
            <li>- Massagem Terapêutica e Craniana.</li>
            <li>- Reflexologista Podal Ortomolecular Holística</li>
            <li>
              - Placas de Ressonância Quântica Otomolecular (tratamento para os
              chakras).
            </li>
            <li>
              - Tratamento Ortomolecular (para equilíbrio e cura física,
              emocional e desequilíbrios orgânicos).
            </li>
          </ul>
        </div>

        <div className="box-servicos__content">
          <h3>
            <strong>Tecnologia para Tratamentos Faciais e Corporais</strong>
          </h3>
          <ul className="box-servicos__lista-servico">
            <li>
              - Criofrequência (rejuvenescimento facial e íntimo, celulite,
              flacidez, gordura localizada e harmonização facial).
            </li>
            <li>
              - Luz pulsada (laser para manchas, rosácea, telangiectasia,
              flacidez, rugas, acne ativa e micro vasos, harmonização facial).
            </li>
            <li>
              - Jato de plasma (remoção de tatuagem, sobrancelha, blefarofagia,
              rugas, flacidez, marcas de acne, melanoses solares/manchas senis e
              estrias).
            </li>
            <li>
              - Indradermoterapia (enzima sem agulha para flacidez, gordura
              localizada, celulite, Capilar-alopecia).
            </li>
            <li>
              - Epilação a Laser com Crystal 3D (remoção de pêlos sem dor e para
              todos tipos de pele).
            </li>
          </ul>
        </div>
      </div>

      <div className="box-servicos__content" style={{ textAlign: "center" }}>
        <h3>
          <strong>Horário de Atendimento </strong>
        </h3>
        <ul className="box-servicos__lista-servico">
          <li>Terça à Sexta 11 às 19h15h.</li>
          <li>Sábado 10 às 15:30h.</li>
          <li>Domingo (Máquinas Agendadas).</li>
        </ul>
      </div>

      <div className="box-rede-social">
        <p>Sigam nossas redes sociais e compartilhem</p>
        <ul className="box-rede-social__lista">
          <li>
            <a
              href="https://www.facebook.com/StudioJoyEsteticaeBemEstar"
              target="_blank"
              className="ico facebook"
              title="Facebook"
              alt="Facebook"
              rel="noreferrer"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/studiojoyestetica?r=nametag"
              target="_blank"
              className="ico instagram"
              title="Instagram"
              alt="Instagram"
              rel="noreferrer"
            >
              Instagram
            </a>
          </li>
          <li>
            <a
              href="https://wa.me/5511911406790"
              target="_blank"
              className="ico whatsapp"
              title="Whatsapp"
              alt="Whatsapp"
              rel="noreferrer"
            >
              Whatsapp
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Home;
